<template>
	<div
		class="stats-card"
		tabindex="0"
		:class="{ active: isActive }"
		@click.prevent="onCardClick(id)"
	>
		<div class="stats-quantity">
			<span class="stats-quantity-number">
				{{ quantity || 0 }}
			</span>
			<span class="stats-quantity-subtitle">
				{{ subTitle }}
			</span>
		</div>
		<h3 class="stats-card-title">
			{{ title }}
		</h3>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Get the all of the filters as a store payload object.
		 *
		 * @return {Object}
		 */
		getFiltersAsObject() {
			let filters = {
				statuses: ['incomplete'],
				urgent: false,
				nonUrgent: false,
			}

			if (!this.paging) {
				filters = { ...filters, types: this.getTypesArray() }
			}

			if (this.urgent) {
				filters.urgent = true
				filters.nonUrgent = false
			}

			if (this.nonUrgent) {
				filters.urgent = false
				filters.nonUrgent = true
			}

			return filters
		},

		getFiltersAsObjectRemove() {
			let filters = {
				statuses: [],
				urgent: false,
				nonUrgent: false,
			}
			if (!this.paging) {
				filters = { ...filters, types: [] }
			}
			return filters
		},

		/**
		 * Get the call type filter array.
		 *
		 * @return {Array}
		 */
		getTypesArray() {
			if (this.id) {
				return { id: this.id, name: this.title }
			}

			return []
		},

		/**
		 * Handle the on card click event.
		 *
		 * @return {void}
		 */
		async onCardClick(cardId) {
			this.$emit('click')
			try {
				await this.setLoading(true)

				if (this.paging) {
					await this.setPagingFilters(this.getFiltersAsObject())

					return (
						this.$route.name === 'app.paging' ||
						this.$router.push({ name: 'app.paging' })
					)
				}
				if (this.isActive) {
					await this.setCallFilters(this.getFiltersAsObjectRemove())
					await this.fetchCalls()
				} else {
					await this.setCallFilters(this.getFiltersAsObject())
					cardId && (await this.getCallTypesCount(cardId))
					await this.fetchCalls()
				}
			} catch (e) {
				await this.setLoading(false)
			} finally {
				await this.setLoading(false)
			}
		},

		...mapActions('calls', {
			fetchCalls: 'get',
			removeFilter: 'removeFilter',
			setCallFilters: 'setFilters',
			setLoading: 'setLoading',
		}),
		...mapActions({ getCallTypesCount: 'callTypes/get' }),

		...mapActions('pages', {
			setPagingFilters: 'setFilters',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'StatsCard',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The type id to be shown.
		 *
		 * @type {Number}
		 */
		id: {
			type: Number,
			default: null,
		},
		/**
		 * Determine if the call type should apply the non-urgent filter when clicked.
		 *
		 * @type {Boolean}
		 */
		nonUrgent: {
			default: false,
			type: Boolean,
		},

		/**
		 * Determine if is a paging card.
		 *
		 * @type {Boolean}
		 */
		paging: {
			type: Boolean,
			default: false,
		},

		/**
		 * The number of calls to be displayed.
		 *
		 * @type {Number}
		 */
		quantity: {
			required: true,
			validator: prop => typeof prop === 'number' || prop === null,
		},

		/**
		 * The card's sub title.
		 *
		 * @type {String}
		 */
		subTitle: {
			required: true,
			validator: prop => typeof prop === 'string' || prop === null,
		},

		/**
		 * The card's title.
		 *
		 * @type {String}
		 */
		title: {
			required: true,
			validator: prop => typeof prop === 'string' || prop === null,
		},

		/**
		 * Determine if the card is urgent.
		 *
		 * @type {Boolean}
		 */
		urgent: {
			type: Boolean,
			default: false,
		},

		isActive: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
